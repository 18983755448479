<template>
  <div class="card w-100 h-100">
    <div class="card-header">
      Ընդհանուր նկարագիր
    </div>
    <div class="tender-card-body">
      <div class="row w-100 p-0 m-0">
        <div class="form-group col-md-6 tender-input-group tender-input-group-left">
          <float-label>
            <input
              v-model="tender.name"
              placeholder="Մրցույթի անվանում"
              type="text"
              name="name"
              class="tender-input pr-3"
            />
          </float-label>
        </div>
        <div class="form-group col-md-6 p-0 d-flex">
          <div class="form-group col-md-4 tender-input-group tender-input-group-center">
            <float-label>
              <input
                  v-model="tender.code"
                  placeholder="Մրցույթի ծածկագիր"
                  type="text"
                  name="name"
                  class="tender-input pr-3"
              />
            </float-label>
          </div>
          <div class="form-group col-md-8 p-0 d-flex">
            <div class="form-group col-md-6 tender-input-group tender-input-group-center">
              <float-label>
                <input
                    v-model="tender.unit"
                    placeholder="Չափման միավոր"
                    type="text"
                    name="unit"
                    class="tender-input pr-3"
                />
              </float-label>
            </div>
            <div class="form-group col-md-6 tender-input-group tender-input-group-right">
              <float-label>
                <input
                    v-model.number="tender.market_price"
                    v-positive-only.none-zero
                    placeholder="Շուկայական գին"
                    type="number"
                    class="tender-input"
                    id="procurement-name-ru"
                >
              </float-label>
            </div>
          </div>
        </div>
        <div class="form-group col-md-6 tender-input-group tender-input-group-left">
          <float-label >
            <textarea
                placeholder="Տեխնիկական բնութագիրը"
                v-model="tender.specification"
                rows="4"
                id="other-requirements"
                class="tender-input h-100 py-2 br-8"
            ></textarea>
          </float-label>
        </div>
        <div class="form-group col-md-6 tender-input-group tender-input-group-right">
          <float-label >
            <textarea
                placeholder="Գնման պայմաններ"
                v-model="tender.terms_of_purchase"
                rows="4"
                id="other-requirements"
                class="tender-input h-100 py-2 br-8"
            ></textarea>
          </float-label>
        </div>
        <div class="form-group col-md-6 tender-input-group tender-input-group-left">
          <float-label >
            <textarea
                placeholder="Վճարման պայմաններ"
                v-model="tender.payment_terms"
                rows="4"
                id="other-requirements"
                class="tender-input h-100 py-2 br-8"
            ></textarea>
          </float-label>
        </div>
        <div class="form-group col-md-6 tender-input-group tender-input-group-right">
          <float-label >
            <textarea
                placeholder="Մատակարարման պայմաններ"
                v-model="tender.delivery_terms"
                rows="4"
                id="other-requirements"
                class="tender-input h-100 py-2 br-8"
            ></textarea>
          </float-label>
        </div>
        <div class="form-group col-md-6 tender-input-group tender-input-group-left margin-bottom-28">
          <label class="m-0 btn" :class="(picturesNames.length) ? 'btn-primary' : 'btn-light'">
            <svg class="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.231 13.0161V4.5H12.6733V13.0161L15.3656 10.3065L16.4233 11.371L12.9617 14.7581L11.904 15.8226L10.9425 14.7581L7.48096 11.371L8.53865 10.3065L11.231 13.0161ZM17.9615 17.9516V15.9193H19.5V17.758C19.5 18.7258 18.7308 19.5 17.7692 19.5H6.23077C5.26923 19.5 4.5 18.7258 4.5 17.758V15.9193H6.03846V17.9516H17.9615Z" fill="#006BE6"/>
            </svg>
            <span>Վերբեռնել Նկարներ</span> <input @change="previewImagesFile($event)" type="file" accept="image/*" multiple hidden>
          </label>
          <div v-if="picturesNames.length" class="helper-text">
            <span :key="index" v-for="(picture, index) in picturesNames">
              {{ picture }}
            </span>
          </div>
          <div v-else class="helper-text">Առավելագույնը 5 նկար</div>
        </div>
      </div>
    </div>

    <confirm-modal confirmBtnText="Հաստատել" declineBtnText="Չեղարկել"/>

  </div>
</template>

<script>
  import 'vue-date-pick/dist/vueDatePick.css';
  import { required } from 'vuelidate/lib/validators'

  // Custom validator to check if the number of files is <= 5
  const maxImages = (value) => !value || value.length <= 5;

  // Custom validator to ensure that all files are images
  const isImageFile = (value) => {
    if (!value) return true; // Allow empty field if not required
    return Array.from(value).every(file => file.type.startsWith('image/'));
  };

  export default {
    name: 'CreateTenderForm',
    props: ['rows', 'tenderData'],
    components: {
      ConfirmModal: () => import('@/components/helpers/ConfirmModal'),
    },
    data() {
      return {
        isSaving: false,
        procedureTypes: [],
        regions: [],
        picturesNames: [],
        tender: {
          id: false,
          name: '',
          code: '',
          market_price: '',
          unit: '',
          specification: '',
          terms_of_purchase: '',
          payment_terms: '',
          delivery_terms: '',
          pictures: [],
        },
      }
    },
    computed: {
      currentOrganized() {
        return this.$store.getters['organize/currentOrganized']
      },
      organizedRows() {
        return this.$store.getters['organize/organizedRows']
      },
      me() {
        return this.$store.getters['user/me']
      },
    },
    mounted() {
      if(this.tenderData){
        this.tender.id = this.tenderData.id;
        this.tender.code = this.tenderData.code;
        this.tender.name = this.tenderData.name;
        this.tender.unit = this.tenderData.unit;
        this.tender.market_price = this.tenderData.market_price;
        this.tender.delivery_terms = this.tenderData.delivery_terms;
        this.tender.payment_terms = this.tenderData.payment_terms;
        this.tender.specification = this.tenderData.specification;
        this.tender.terms_of_purchase = this.tenderData.terms_of_purchase;
        this.tender.pictures = this.tenderData.pictures;
        this.picturesNames = this.tenderData.pictures;
      }
    },
    methods: {
      previousDate(date){
        var d = new Date();
        var x = 1;
        return date < d.setDate(d.getDate() - x)
      },
      previousEndDate(date){
        var d = new Date(this.tender.start_date);
        var x = 0;
        return date < d.setDate(d.getDate() - x)
      },
      previewImagesFile(event) {
        const files = event.target.files;
        this.tender.pictures = event.target.files;
        console.log()
        this.tender.pictures.forEach(picture => {
          this.picturesNames.push(picture.name);
        })

        this.$v.tender.pictures.$touch();

        if (files.length > 5) {
          alert('You can only upload a maximum of 5 images.');
          return;
        }

        // Loop through files and ensure they are images
        for (const file of files) {
          if (!file.type.startsWith('image/')) {
            alert('Only image files are allowed.');
            return;
          }
        }

        // Process the valid image files
        // For example, upload or preview them
        console.log('Selected files:', files);
      },
      previewInvitationFile(event){
        const file = event.target.files[0]
        if(file.size < 100000000){
          this.tender.invitation_file = file;
          this.invitation_file_name = file.name
        } else {
          this.$notify({
            group: 'foo',
            type: 'error',
            text: 'Ընտրված ֆայլի առավելագույն ծավալը պետք է լինի 10ՄԲ'
          })
        }
      },
      getSelectedMembers(members){
        console.log('members', members)
        this.tender.members = JSON.stringify(members);
        console.log('this.tender', this.tender)
      },
      confirm() {
        console.log(this.$v.tender);
        if(!this.$v.tender.$invalid) {
          if(this.rows.length){
            if(this.tenderData){
              this.isSaving = true;
              const formData = new FormData();
              Object.keys(this.tender).forEach((key) => {
                if (key !== 'pictures'){
                  formData.append(key, this.tender[key]);
                }
              });
              for (let i = 0; i < this.tender.pictures.length; i++) {
                formData.append(`pictures[]`, this.tender.pictures[i]);
              }
              const cpvArray = this.rows.map(row => row.cpv_id ? row.cpv_id : row.id);
              formData.append('cpvs', JSON.stringify(cpvArray));
              console.log(formData, 'formData');
              this.$client.post(`timeless-tenders/update/${this.tenderData.id}`, formData).then(({data}) => {
                if(data){
                  this.$notify({
                    group: 'foo',
                    type: 'success',
                    text: 'Հաջողությամբ խմբագրված է'
                  })
                  this.isSaving = false;
                  this.$router.push('/organizator/timeless-tenders/list')
                }
              })
            } else {
              this.isSaving = true;
              const formData = new FormData();
              Object.keys(this.tender).forEach((key) => {
                formData.append(key, this.tender[key]);
              });
              for (let i = 0; i < this.tender.pictures.length; i++) {
                formData.append(`pictures[]`, this.tender.pictures[i]);
              }
              const cpvArray = this.rows.map(row => row.cpv_id ? row.cpv_id : row.id);
              formData.append('cpvs', JSON.stringify(cpvArray));
              console.log(formData, 'formData');
              this.$client.post('timeless-tenders', formData).then(({data}) => {
                if(data){
                  this.$notify({
                    group: 'foo',
                    type: 'success',
                    text: 'Հաջողությամբ հաստատված է'
                  })
                  this.isSaving = false;
                  this.$router.push('/organizator/timeless-tenders/list')
                }
              }).catch(() => {
                this.$notify({
                  group: 'foo',
                  type: 'error',
                  text: 'Լրացրեք բոլոր դաշտերը հաստատելու համար!'
                })
              })
            }
          } else {
            this.$notify({
              group: 'foo',
              type: 'error',
              text: 'Ընտրեք գոնե մեկ կատեգորիա հաստատելու համար'
            })
          }
        } else {
          this.$notify({
            group: 'foo',
            type: 'error',
            text: 'Լրացրեք բոլոր դաշտերը հաստատելու համար!'
          })
        }
      },
    },
    validations() {
      const rules = {
        tender: {
          name: { required },
          code: { required },
          market_price: { required },
          specification: { required },
          terms_of_purchase: { required },
          payment_terms: { required },
          delivery_terms: { required },
        },
      }
      if(this.tender.id === false){
        Object.assign(rules, {
          pictures: { required, maxImages, isImageFile },
        })
      }
      return rules
    }
  }
</script>

<style scoped>

</style>
